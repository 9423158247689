<template>
  <div class="about-page">
<div class="gif-container">
    <img src="@/assets/img/myinstagram-gif.gif"  class="gif" alt="">
    <div class="welcome-txt">
                Build from scratch with Vue, Vuex <br/>
                <br/>
                Node.js and MongoDB, <br/>
                <br/>
                
    </div>
</div>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>