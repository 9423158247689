<template>
  <section class="app-footer">
    <footer>  © Coffeerights  My-Instegram International Ltd 2021 </footer>
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>
  
